.select {
  border: 1px solid #eaedf1;
  color: #4d5875;
  background: #fff;
  box-shadow: none;
  border-radius: 7px;

  background-image: url("../../../../assets/images/svgs/chevron-down.svg") !important;
  background-repeat: no-repeat !important;
  background-position: right 6px center !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  overflow: hidden;
}
