.login-language {
  position: fixed;
  top: 0;
  right: 0;
  width: 125px;
  margin: 2rem 4rem;
  z-index: 999;
}

.transition {
  transition: ease-in-out 1s;
}

@media (max-width: 480px) {
  .login-language {
    margin: 1rem;
  }

  .horizontalMenu-list.xl-lg-nav {
    display: none;
  }
}

@media (max-width: 956px) {
  .horizontalMenu-list.xl-lg-nav,
  .no-dropdown {
    display: none !important;
  }

  .submenu-dropdown {
    display: block !important;
  }
}

@media (min-width: 956px) {
  .horizontalMenu-list.mobile-nav {
    display: none;
  }
}

.first-menu {
  margin-top: 1.5rem !important;
}

.last-menu {
  margin-bottom: 2rem !important;
}

.first-menu .active {
  margin-top: 1.8rem !important;
}

.pointer {
  cursor: pointer;
}

.shadow:hover {
  transform: translateY(-1px);
  transition: 1s ease-in-out;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.margin-label {
  margin-top: 1.8rem;
}

.mr-2rem {
  margin-right: 2rem;
}

.mt-4rem {
  margin-top: 4rem;
}

.mb-3rem {
  margin-bottom: 3rem;
}

.fs-8px {
  font-size: 10px !important;
  line-height: 1;
  text-overflow: ellipsis;
}

.width-25 {
  width: 25% !important;
}

.width-50 {
  width: 50% !important;
}

.width-100 {
  width: 100% !important;
}

.text-right {
  text-align: right;
}

.load-more {
  font-size: 11px;
  color: #a8afc7;
  padding: 0.8rem;
  text-align: center;
  border-top: 1px solid #e8e8f7;
}

.load-more:hover {
  cursor: pointer;
  background-color: #f9faff;
  border-top-color: #e8e8f7;
  border-bottom-color: #e8e8f7;
}

.load-more-products {
  font-size: 15px;
  color: #a8afc7;
  padding: 0.8rem;
  text-align: center;
  border-top: 1px solid #e8e8f7;
  cursor: pointer;
  font-weight: bold;
}

.load-more-products:hover {
  cursor: pointer;
  color: #6259ca;
  background-color: #f9faff;
  border-top-color: #e8e8f7;
  border-bottom-color: #e8e8f7;
  font-weight: bold;
}

.otp-pin-input-bar {
  border-bottom: 2px solid #e1e1e1;
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 10px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  width: 30px;
  color: #0d0c22;
  font-weight: 500;
  margin-top: -10px;
  margin-bottom: 20px;
  font-family: var(--font-style);
}

.input-100 {
  font-size: 15px;
  line-height: 1.5;
  color: #000;
  display: block;
  width: 100%;
  height: 35px;
  padding: 0 10px;
  border-radius: none;
  border-bottom: 1px solid #ecf0fa;
  border-top: none;
  border-left: none;
  border-right: none;
}

.input-100::placeholder,
.eye-right i {
  color: #03014c;
  font-size: 13px;
}

.height-30 {
  height: 30% !important;
}

.height-70 {
  height: 70% !important;
}

.height-100 {
  height: 100% !important;
}

.loader-modal {
  padding: 20px;
  position: absolute;
  top: 200px;
  width: 95%;
  right: 2%;
  height: auto;
  z-index: 1;
}

.openModal:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  content: "";
}

.d-flex.avatar-flex {
  position: relative;
  justify-content: center !important;
}

.edit-avatar {
  position: absolute;
  display: inline-block !important;
  right: 25%;
  top: 25%;
}

.rounded-circle {
  cursor: pointer;
}

/*.rounded-circle.avatar-lg {
}*/

.rounded-circle.avatar-md {
  position: absolute;
  right: 0;
  top: 4rem;
}

.rounded-circle.avatar-md.upload-avatar {
  position: absolute;
  right: -3rem;
  top: 4rem;
}

.edit-avatar .rounded-circle i {
  margin: 0 10px;
  cursor: pointer;
}

.browse-file input[type="file"] {
  cursor: pointer;
  position: absolute;
  width: 100%;
  right: 0;
  opacity: 0;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

#button-tooltip > .tooltip-inner {
  background-color: #808080;
  color: #fff;
  font-size: 12px;
  padding: 2px 8px;
}

.login-img.construction {
  background: #6259ca;
}

.activity-fig {
  text-align: end;
}

.card.wallets {
  min-height: 14rem;
}

.wallets .wallets-button {
  position: absolute;
  bottom: 5%;
}

.center-span {
  width: 100%;
  text-align: center;
}

img {
  text-indent: -9999px;
}

.card-dashboard-center {
  align-items: center;
  justify-content: center;
}

.table-and-hover tbody tr:hover td {
  background-color: #686868;
  color: #fff;
  cursor: pointer;
}
