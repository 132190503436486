input {
  outline: none;
  border: none;

  &:focus {
    &::-webkit-input-placeholder {
      color: transparent;
    }

    &:-moz-placeholder {
      color: transparent;
    }

    &::-moz-placeholder {
      color: transparent;
    }

    &:-ms-input-placeholder {
      color: transparent;
    }
  }

  &::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &:-moz-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &::-moz-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
}

textarea {
  outline: none;
  border: none;

  &:focus {
    &::-webkit-input-placeholder {
      color: transparent;
    }

    &:-moz-placeholder {
      color: transparent;
    }

    &::-moz-placeholder {
      color: transparent;
    }

    &:-ms-input-placeholder {
      color: transparent;
    }
  }

  &::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &:-moz-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &::-moz-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
}

.input-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f82649;
}

button {
  outline: none !important;
  border: none;
  background: transparent;

  &:hover {
    cursor: pointer;
  }
}

iframe {
  border: none !important;
}

.vertical-middle {
  vertical-align: middle !important;
}

.txt1 {
  font-size: 13px;
  line-height: 1.5;
  color: #24214c;
}

.txt2 {
  font-size: 13px;
  line-height: 1.5;
  color: #24214c;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  z-index: 999;
}

.wrap-login100 {
  color: #03014c;
  background: #fff;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 137px 130px 137px 95px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

.login100-pic {
  width: 316px;

  img {
    max-width: 100%;
  }
}

.login100-form {
  width: 320px;
}

.login100-form-title {
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 30px;
  color: #424344;
}

.wrap-input100 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 10px;
}

.input100 {
  font-size: 15px;
  line-height: 1.5;
  color: #000;
  display: block;
  width: 100%;
  background: #ffffff;
  height: 45px;
  padding: 0 30px 0 54px;
  border-radius: 5px;
  border: 1px solid #ecf0fa;
}

.input100::placeholder,
.eye-right i {
  color: #03014c;
  font-size: 13px;
}

.focus-input100 {
  display: block;
  position: absolute;
  border-radius: 5px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 0;
  text-transform: uppercase;
  background: #6259ca;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.symbol-input100 {
  font-size: 17px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 5px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 22px;
  pointer-events: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  color: #828996;
}

.eye-right {
  font-size: 17px;
  color: #828996;
  display: inline-block;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 8px;
  cursor: pointer;
  padding-right: 15px;

  &.sign-up {
    top: 38px;
  }
}

#confirmPassword.form-control.is-invalid {
  background-image: none !important;
}

.input100.focus-input100 + .symbol-input100 {
  text-transform: uppercase;
  background: #6259ca;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 15px;
}

.login100-form-btn {
  line-height: 1.5;
  color: #fff;
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  border-radius: 5px;
}

.btn-social {
  position: relative;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #e2e7eb;
  padding-left: 60px;
  background-color: #fff;

  &:first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    line-height: 36px;
    font-size: 1.2em;
    text-align: center;
    border-right: 1px solid #ecf0fa;
  }
}

.btn-social.btn-lg {
  padding-left: 61px;

  &:first-child {
    line-height: 45px;
    width: 45px;
    font-size: 1.8em;
  }
}

.btn-social.btn-sm {
  padding-left: 38px;

  &:first-child {
    line-height: 28px;
    width: 28px;
    font-size: 1.4em;
  }
}

.btn-social.btn-xs {
  padding-left: 30px;

  &:first-child {
    line-height: 20px;
    width: 20px;
    font-size: 1.2em;
  }
}

.btn-social-icon {
  position: relative;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 34px;
  width: 34px;
  padding: 0;

  &:first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    line-height: 34px;
    font-size: 1.6em;
    text-align: center;
    border: none;
    width: 100%;
    color: #fff;
  }
}

.btn-social-icon.btn-lg {
  height: 45px;
  width: 45px;
  padding-left: 0;
  padding-right: 0;

  &:first-child {
    line-height: 45px;
    width: 45px;
    font-size: 1.8em;
  }
}

.btn-social-icon.btn-sm {
  height: 30px;
  width: 30px;
  padding-left: 0;
  padding-right: 0;

  &:first-child {
    line-height: 28px;
    width: 28px;
    font-size: 1.4em;
  }
}

.btn-social-icon.btn-xs {
  height: 22px;
  width: 22px;
  padding-left: 0;
  padding-right: 0;

  &:first-child {
    line-height: 20px;
    width: 20px;
    font-size: 1.2em;
  }
}

.transition {
  transition: ease-in-out 1s;
}

@media (max-width: 992px) {
  .wrap-login100 {
    padding: 35px 90px 35px 85px;
  }
  .login100-pic {
    width: 35%;
    display: none;
  }
  .login100-form {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .wrap-login100 {
    padding: 30px 80px 30px 80px;
  }
  .login100-form {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 25px 15px 25px 15px;
  }
}

.react-datepicker {
  font-family: Helvetica, "sans-serif";
  font-size: 1rem !important;
}

.react-datepicker__current-month {
  color: rgb(72, 72, 72) !important;
}

.react-datepicker-time__header {
  color: rgb(72, 72, 72) !important;
}

.react-datepicker-year-header {
  color: rgb(72, 72, 72) !important;
}

.react-datepicker__header {
  background-color: #f0f0f0;
  border-bottom: none !important;
}

.react-datepicker__day--selected {
  border-radius: 0 !important;
}

.react-datepicker__input-container {
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 6px;

  input {
    width: 100%;
  }
}

.react-datepicker__navigation {
  top: 15px !important;
}

.react-datepicker__navigation--previous {
  background: url(../images/svgs/nav-left-arrow.svg) no-repeat;
  width: 40px;
  height: 30px;
  margin: 0 0 0 20px;
}

.react-datepicker__navigation--next {
  background: url(../images/svgs/nav-right-arrow.svg) no-repeat;
  width: 40px;
  height: 30px;
  margin: -2px 0 0 20px;
}

.react-datepicker__navigation-icon {
  &::before {
    border-style: none;
  }
}

.slick-slide {
  width: 195px;

  > div {
    margin: 0 8px;
  }

  .card-body {
    padding: 1rem 0.8rem;
  }
}

.slick-prev {
  left: -10px;
}

//.slick-slide.slick-active, .slick-slide.slick-active.slick-cloned {
//  width: 195px !important;
//}

//.slick-list {
//  margin: 0 -10px;
//}

.table > :not(:first-child) {
  border-top: none !important;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em !important;
  opacity: 0.5;

  &:before {
    content: "";
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 0.5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #fcfcfa;
  }
}

.dropify-wrapper {
  display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: 250px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000;
  background-color: #fff;
  background-image: none;
  text-align: center;
  border: 1px solid #f0f0f4;
  -webkit-transition: border-color 0.15s linear;
  transition: border-color 0.15s linear;
}

.dropify-wrapper:hover {
  background-size: 30px 30px;
  background-image: -webkit-linear-gradient(
    135deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    -45deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  );
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite;
}

.dropify-wrapper .dropify-message {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dropify-wrapper input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 5;
}

.dropify-wrapper .dropify-message span.file-icon {
  font-size: 50px;
  color: #68798b;
}

.dropify-wrapper .dropify-message p {
  margin: 5px 0 0 0;
  font-size: 14px;
}

.hover-underline:hover {
  text-decoration: underline;
}

.wizard.kyc {
  border: none;
  background-color: transparent;
}

.wizard.kyc > .steps li.disabled a .number {
  background-color: #c4c4c4;
}

.wizard.kyc > .steps > ul li {
  float: none;
  display: block;
  width: 33.3%;
}

//@media (max-width < 991px) {
@media only screen and (max-width: 768px) {
  .wizard.kyc > .steps .disabled {
    display: none;
  }
}
