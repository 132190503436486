@import "./variables";
body {
  color: #0d0c22;
  font-family: var(--font-style);
  background-color: var(--background-color);
}

.header, .footer, .horizontalMenu, .horizontal-main.hor-menu, .mega-menubg {
  background: $brand;
}

.bg-success {
  background: $success;
}

.bg-pending {
  background: $warning;
}

.bg-danger {
  background: $red;
}

.bg-info {
  background: $info;
}

.bg-muted {
  background: $muted;
}

a {
  color: $nav-text;

  &:hover {
    color: $nav-text;
  }
}

.nav-link.icon i {
  color: $nav-text;
}

.edit-avatar .rounded-circle {
  border: solid 2px $brand-label;
  background: white;

  i {
    color: $brand-label;
  }
}

.page-title {
  color: $brand-text;
}

.bg-primary {
  background: $brand-label !important;
}

a.bg-primary {
  &:hover {
    background-color: $primary !important;
  }

  &:focus {
    background-color: $brand !important;
  }
}

button.bg-primary {
  &:hover {
    background-color: $brand !important;
  }

  &:focus {
    background-color: $brand !important;
  }
}

.bg-primary-gradient {
  background: linear-gradient(to bottom right, $brand 100%, $white 0%) !important;
}

a.bg-primary-gradient {
  &:hover {
    background-color: $brand !important;
  }

  &:focus {
    background-color: $brand !important;
  }
}

button.bg-primary-gradient {
  &:hover {
    background-color: $brand !important;
  }

  &:focus {
    background-color: $brand !important;
  }
}

.border-primary {
  border-color: $brand !important;
}

.text-primary {
  color: $brand-text !important;
}

a.text-primary {
  &:hover {
    color: $brand-text;
  }

  &:focus {
    color: $brand-text;
  }
}

.btn-primary {
  color: $primary-text !important;
  background: $primary !important;
  border-color: $primary !important;

  &:hover {
    color: $primary-text;
    background-color: $primary-hover;
    border-color: $primary-hover;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
  }

  &:disabled {
    color: $primary-text;
    background: $primary;
    border-color: $primary;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: $primary-text;
        background: $primary-active;
        border-color: $primary-active;

        &:focus {
          box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
        }
      }
    }

    &:not(.disabled).active {
      color: $primary-text;
      background: $primary-active;
      border-color: $primary-active;

      &:focus {
        box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
      }
    }
  }
}

.btn-primary.focus {
  box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}

.btn-primary.disabled {
  color: $primary-text;
  background: $primary;
  border-color: $primary;
}

.btn-secondary {
  color: $secondary-text !important;
  background: $secondary !important;
  border-color: $secondary !important;

  &:hover {
    color: $secondary-text;
    background-color: $secondary-hover;
    border-color: $secondary-hover;
  }

  &:disabled {
    color: $secondary-text;
    background: $secondary;
    border-color: $secondary;
  }
}

.btn-danger {
  color: $danger-text !important;
  background: $danger !important;
  border-color: $danger !important;

  &:hover {
    color: $danger-text;
    background-color: $danger-hover;
    border-color: $danger-hover;
  }

  &:disabled {
    color: $danger-text;
    background: $danger;
    border-color: $danger;
  }
}

.show {
  > .btn-primary.dropdown-toggle {
    color: $primary-text;
    background: $primary;
    border-color: $primary;

    &:focus {
      box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
    }
  }
}

.btn-link {
  color: $nav-text;

  &:hover {
    color: $brand;
  }
}

.drop-icon-wrap {
  .drop-icon-item {
    &:hover {
      color: $brand;
    }
  }
}

.dropdown-item {
  &:hover {
    color: $nav-text;
  }

  &:focus {
    color: $nav-text;
  }

  &:active {
    color: $nav-text;
  }
}

.dropdown-item.active {
  color: $nav-text;
}

.custom-control-input {
  &:checked ~ .custom-control-label {
    &::before {
      color: $brand-text;
      background-color: $brand;
      border-color: $brand;
    }
  }
}

.custom-checkbox {
  .custom-control-input {
    &:checked ~ .custom-control-label {
      &::before {
        background-color: $brand-label;
      }
    }

    &:indeterminate ~ .custom-control-label {
      &::before {
        background-color: $brand-label;
      }
    }

    &:disabled {
      &:checked ~ .custom-control-label {
        &::before {
          background-color: $brand-label;
        }
      }
    }
  }
}

.custom-radio {
  .custom-control-input {
    &:checked ~ .custom-control-label {
      &::before {
        background-color: $brand;
        border-color: $brand;
      }
    }

    &:disabled {
      &:checked ~ .custom-control-label {
        &::before {
          background-color: $brand;
        }
      }
    }
  }
}

.custom-range {
  &::-webkit-slider-thumb {
    background-color: $brand;
  }

  &::-moz-range-thumb {
    background-color: $brand;
  }

  &::-ms-thumb {
    background-color: $brand;
  }
}

.nav-pills {
  .nav-link.active {
    background-color: $brand;
  }

  .show {
    > .nav-link {
      background-color: $brand;
    }
  }
}

.page-link {
  &:hover {
    color: $brand-label;
  }
}

.page-item.active {
  .page-link {
    color: $brand;
    background-color: $brand-label;
    border-color: $brand-label;
  }
}

.range.range-primary {
  input[type="range"] {
    &::-webkit-slider-thumb {
      background-color: $brand;
    }

    outline-color: $brand;
  }

  output {
    background-color: $brand;
  }
}

.panel.price {
  > .panel-heading {
    background: $brand;
  }
}

.nav-tabs {
  .nav-item1 {
    .nav-link {
      &:hover {
        &:not(.disabled) {
          background: $brand;
        }
      }
    }

    .nav-link.active {
      background: $brand;
    }
  }

  .nav-link {
    &:hover {
      &:not(.disabled) {
        background: $brand;
      }
    }
  }

  .nav-link.active {
    background: $brand;
  }
}

.pop-primary {
  color: $brand;
}

.breadcrumb-item.active {
  color: $nav-text;
}

.breadcrumb-item1 {
  a {
    color: $brand;
  }
}

.breadcrumb-item2 {
  a {
    color: $brand;
  }
}

.panel-title1 {
  a {
    background: #F2F1F9 !important;
  }
}

.btn.dropdown-toggle.btn-primary ~ .dropdown-menu {
  .dropdown-plus-title {
    border-color: $brand !important;
  }
}

.panel-default {
  > .panel-heading {
    color: $brand;
  }
}

.alert-primary {
  color: #6259ca;
  background-color: rgba(98, 89, 202, 0.1);
  border-color: rgba(98, 89, 202, 0.01);

  hr {
    border-top-color: #6259ca;
  }

  .alert-link {
    color: #6259ca;
  }
}

.list-group-item-primary {
  color: #6259ca;
  background-color: #cbdbf2;
}

.list-group-item-primary.list-group-item-action {
  &:hover {
    color: #6259ca;
    background-color: #b7cded;
  }

  &:focus {
    color: #6259ca;
    background-color: #b7cded;
  }
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #6259ca;
  border-color: #6259ca;
}

.header-bg {
  background: $brand;
  background: -webkit- linear-gradient(to bottom right, $white 0%, $brand 100%) !important;
  background: linear-gradient(to bottom right, $white 0%, $brand 100%) !important;
}

.footer {
  a {
    &:not(.btn) {
      color: $nav-text;
    }
  }
}

.avatar {
  background: #e3e1f5 no-repeat center/cover;
}

#airtime.avatar {
  background: #fff no-repeat center/cover;
}

.spinner {
  background-color: $brand;
}

.spinner-lg {
  background-color: $brand;
}

.double-bounce1 {
  background-color: $brand-label;
}

.double-bounce2 {
  background-color: $brand;
}

a.chip {
  &:hover {
    background-color: $brand;
  }
}

.tag-primary {
  background-color: $brand;
  color: $brand-text;
}

.selectgroup-input {
  &:checked + .selectgroup-button {
    border-color: #6259ca;
    z-index: 1;
    color: #6259ca;
    background: #f6f4fb;
  }

  &:focus + .selectgroup-button {
    border-color: #6259ca;
    z-index: 2;
    color: #6259ca;
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.25);
  }
}

.custom-switch-input {
  &:checked ~ .custom-switch-indicator {
    background: $brand;
  }
}

.ui-datepicker {
  .ui-datepicker-title {
    color: #6259ca;
  }
}

.timeline--horizontal {
  .timeline-divider {
    background: #6259ca !important;
  }
}

#back-to-top {
  background-image: linear-gradient(to bottom right, #9e88f5 0%, #6259ca 100%);

  &:hover {
    color: #6259ca !important;
    border: 2px solid #6259ca;
  }
}

.tabs-menu1 {
  ul {
    li {
      .active {
        border-bottom: 3px solid $brand;
      }
    }
  }
}

.tab-content {
  i {
    color: $brand;
  }

  .btn {
    i {
      color: #fff;
    }
  }
}

.tabs-menu2 {
  ul {
    li {
      .active {
        color: $brand;
      }
    }
  }
}

.rating-stars {
  .rating-stars-container {
    .rating-star.is--active {
      .fa-heart {
        color: #6259ca;
      }
    }

    .rating-star.is--hover {
      .fa-heart {
        color: #6259ca;
      }
    }
  }
}

.message-feed {
  &:not(.right) {
    .mf-content {
      background: $brand;
      color: #fff;

      &:before {
        border-right-color: $brand;
      }
    }
  }
}

.msb-reply {
  button {
    background: $brand;
  }
}

.wizard-card {
  .moving-tab {
    margin-top: 5px;
    background: linear-gradient(to bottom right, $white 0%, $brand 100%);
  }
}

div.conv-form-wrapper {
  div.options {
    div.option {
      color: #6259ca;
      border: 1px solid #6259ca;
    }

    div.option.selected {
      background: #6259ca;
      color: #fff;

      &:hover {
        background: #6259ca;
      }
    }
  }

  div#messages {
    div.message.from {
      background: #6259ca;
    }
  }
}

form.convFormDynamic {
  button.submit {
    color: #6259ca;
    border: 1px solid #6259ca !important;

    &:hover {
      background: #6259ca !important;
      color: #fff;
    }
  }
}

.addui-slider {
  .addui-slider-track {
    .addui-slider-handle {
      &:after {
        background: #6259ca;
      }
    }

    .addui-slider-range {
      background: #6259ca;
    }
  }
}

.accordionjs {
  .acc_section.acc_active {
    > .acc_head {
      background: #F2F1F9;
      color: #6259ca !important;
    }
  }
}

.tab_wrapper {
  > ul {
    li.active {
      border-color: #6259ca;
      background: #6259ca;
    }
  }
}

.tab_wrapper.right_side {
  > ul {
    li.active {
      &:after {
        background: #6259ca;
      }
    }
  }
}

.weather-card {
  .top {
    background: #6259ca;
  }
}

.primary {
  .pricing-divider {
    background: #6259ca !important;
  }
}

.product-grid6 {
  .title {
    a {
      &:hover {
        color: #6259ca;
      }
    }
  }

  .icons {
    li {
      a {
        color: #6e84a3;
        border: 1px solid #eaedf1;

        &:hover {
          color: #fff;
          background-image: linear-gradient(to bottom right, #9e88f5 0%, #6259ca 100%);
        }

        &:after {
          background-image: linear-gradient(to bottom right, #9e88f5 0%, #6259ca 100%);
        }

        &:before {
          background-image: linear-gradient(to bottom right, #9e88f5 0%, #6259ca 100%);
        }
      }
    }
  }
}

.card-counter.primary {
  background-image: linear-gradient(to bottom right, #9e88f5 0%, #6259ca 100%);
  color: #FFF;
}

#user-profile {
  .profile-details {
    a {
      > i {
        color: #6259ca;
      }
    }

    i {
      color: #6259ca;
    }
  }
}

.text-primary-gradient {
  background: linear-gradient(to bottom right, #9e88f5 0%, #6259ca 100%);
}

.chat {
  .msg_head {
    background: #6259ca;
  }
}

.top-footer {
  p {
    &:hover {
      color: #6259ca;
    }
  }

  a {
    address {
      &:hover {
        color: #6259ca;
      }
    }

    &:hover {
      color: #6259ca;
    }
  }
}

.footer-payments {
  a {
    &:hover {
      color: #6259ca;
    }
  }
}

.footer-social-list {
  a {
    color: #6259ca;

    &:hover {
      color: #6259ca;
    }
  }
}

.sw-theme-dots {
  .nav-tabs {
    .nav-link {
      &:hover {
        &:not(.disabled) {
          color: #6259ca;
        }
      }
    }
  }
}

.label-primary {
  background: #6259ca;
  color: #fff;
}

.bg-primary-transparent {
  background-color: rgba(98, 89, 202, 0.1);
}

.text-primary-shadow {
  text-shadow: 0 5px 10px rgba(98, 89, 202, 0.3);
}

.chart-dropshadow-primary {
  -webkit-filter: drop-shadow(-6px 12px 4px rgba(98, 89, 202, 0.2));
  filter: drop-shadow(-6px 12px 4px rgba(98, 89, 202, 0.2));
}

.fc-toolbar {
  .fc-state-active {
    background: #544bb9;
  }

  .ui-state-active {
    background: #544bb9;
  }
}

.side-menu__item.active {
  color: #ffffff;

  .side-menu__icon {
    color: #ffffff !important;
  }
}

.hor-menu {
  .horizontalMenu {
    > .horizontalMenu-list {
      > li {
        > a.active {
          color: $nav-text-active;
        }

        > a {
          &:hover {
            color: $nav-text-active;
          }
        }
      }
    }
  }
}

.horizontalMenu {
  > .horizontalMenu-list {
    > li {
      > ul.sub-menu {
        > li {
          > a {
            &:hover {
              color: $brand-text;
            }
          }

          > ul.sub-menu {
            > li {
              > a {
                &:hover {
                  color: $brand-text;
                }
              }
            }
          }
        }
      }

      > .horizontal-megamenu {
        .link-list {
          li {
            a {
              &:hover {
                color: $brand-text;
              }
            }
          }
        }
      }
    }
  }
}

.onoffswitch-checkbox {
  &:checked + .onoffswitch-label {
    background-color: #6259ca;
    border-color: #6259ca;

    &:before {
      border-color: #6259ca;
    }
  }
}

.onoffswitch2-checkbox {
  &:checked + .onoffswitch2-label {
    background-color: #6259ca;

    &:before {
      border-color: #6259ca;
    }
  }

  &:checked + .onoffswitch-label2 {
    border-color: #6259ca;
  }
}

.color-hor-header {
  .header.hor-header {
    background: $brand;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.light-hor-menu {
  .hor-menu {
    .horizontalMenu {
      > .horizontalMenu-list {
        > li {
          > a.active {
            color: #fff;
            background: #6259ca;
          }

          > a {
            &:hover {
              color: #fff;
              background: #6259ca;
            }
          }
        }
      }
    }
  }
}


.light-menu {
  .side-menu__item.active {
    .side-menu__icon {
      color: #fff;
    }

    color: #6259ca !important;
  }

  .side-menu__item {
    &:hover {
      .side-menu__icon {
        color: #6259ca !important;
      }

      color: #6259ca !important;
    }

    &:focus {
      .side-menu__icon {
        color: #6259ca !important;
      }

      color: #6259ca !important;
    }
  }
}

.default-body {
  .app-sidebar {
    background: #6259ca !important;
  }
}

.transparent-mode {
  .side-menu__item.active {
    .side-menu__icon {
      color: #6259ca;
    }

    color: #6259ca;
  }

  .side-menu__item {
    &:hover {
      .side-menu__icon {
        color: #6259ca;
      }

      color: #6259ca;
    }

    &:focus {
      .side-menu__icon {
        color: #6259ca;
      }

      color: #6259ca;
    }
  }
}

.horizontal-main2.horizontal-main {
  background: #6259ca;
}

.color-menu {
  .app-sidebar {
    background: #6259ca !important;
  }

  .side-header {
    background: #6259ca;
    border-bottom: 1px solid #7d76c5;
  }

  .side-menu__item.active {
    .side-menu__icon {
      color: #fff !important;
    }

    color: #fff !important;
  }

  .side-menu__item {
    &:hover {
      .side-menu__icon {
        color: #fff !important;
      }

      color: #fff !important;
      background: #7772dc !important;

      .side-menu__label {
        color: #ffffff !important;
      }
    }

    &:focus {
      .side-menu__icon {
        color: #fff !important;
      }

      color: #fff !important;
      background: #7772dc !important;

      .side-menu__label {
        color: #ffffff !important;
      }
    }

    color: #cccef5;
  }

  .side-menu {
    h3 {
      color: #b3aee8 !important;
    }

    .side-menu__icon {
      color: #cccef5 !important;
    }

    .side-menu__item {
      color: #cccef5 !important;
    }
  }

  .slide.is-expanded {
    a {
      color: #cccef5 !important;
    }
  }

  .slide-item {
    color: #cccef5;
  }

  .slide-menu {
    li {
      .slide-item {
        &:before {
          color: #cccef5;
        }
      }
    }
  }
}

.gradient-menu {
  .side-menu__item.active {
    .side-menu__icon {
      color: #ccc9ec !important;
    }

    color: #ccc9ec !important;
  }

  .side-menu__item {
    &:hover {
      .side-menu__icon {
        color: #ccc9ec;
      }

      color: #ccc9ec !important;

      .side-menu__label {
        color: #ccc9ec;
      }
    }

    &:focus {
      .side-menu__icon {
        color: #ccc9ec;
      }

      color: #ccc9ec !important;

      .side-menu__label {
        color: #ccc9ec;
      }
    }
  }
}

.color-menu.sidemenu-bgimage {
  .side-header {
    &:before {
      background: rgba(98, 89, 202, 0.94);
    }
  }

  .app-sidebar {
    &:before {
      background: rgba(98, 89, 202, 0.94);
    }
  }
}

.transparent-mode.color-hor-menu {
  .horizontal-main.hor-menu {
    background: #6259ca;
  }
}

.dark-mode.color-hor-menu {
  .horizontal-main.hor-menu {
    background: #6259ca;
  }
}

.light-mode.color-hor-menu {
  .horizontal-main.hor-menu {
    background: #6259ca;
  }
}

.color-hor-menu {
  .horizontal-main.hor-menu {
    background: #6259ca;
  }
}

@media (max-width: 992px) {
  .nav-item.with-sub {
    .sub-item {
      border-top: 2px solid #6259ca;
    }
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .search-show {
    .search-element {
      background-image: linear-gradient(to bottom right, #9e88f5 0%, #6259ca 100%);
    }
  }
}

@media (max-width: 576px) {
  .search-show {
    .search-element {
      background-image: linear-gradient(to bottom right, #9e88f5 0%, #6259ca 100%);
    }
  }
}

@media only screen and (max-width: 991px) {
  .dark-mode {
    .horizontalMenu {
      > .horizontalMenu-list {
        background: #30304d;
      }
    }
  }
  .color-hor-menu {
    .horizontalMenu {
      > .horizontalMenu-list {
        background: #6259ca;

        > li {
          > ul.sub-menu {
            background-color: #3d357d;

            > li {
              > ul.sub-menu {
                background-color: #393275;
              }
            }
          }

          > .horizontal-megamenu {
            .link-list {
              li {
                a {
                  background-color: #3d357d !important;
                }
              }
            }
          }
        }
      }
    }

    .mega-menubg {
      background: #3d357d !important;
    }
  }
}

@media (max-width: 767px) {
  .color-hor-header {
    .mobile-header {
      background: #6259ca;
    }
  }
  .color-menu {
    .mobile-header {
      background: #6259ca !important;
    }
  }
}
