$background: var(--background-color);
$brand: var(--navbar-color);
$brand-label: var(--labels-color);
$brand-text: var(--page-title-color);
$nav-text: var(--navbar-text-color);
$nav-text-active: var(--navbar-text-active-color);
$border: 1px solid #eaedf1;

$primary-1: #6259ca;
$primary: var(--submit-button-color);
$primary-text: var(--submit-button-text-color);
$primary-hover: var(--submit-button-hover-color);
$primary-active: var(--submit-button-active-color);

$secondary: #fb6b25;
$secondary: var(--regular-button-color);
$secondary-text: var(--regular-button-text-color);
$secondary-hover: var(--regular-button-hover-color);
$secondary-active: var(--regular-button-active-color);

$danger: #f82649;
$danger: var(--delete-button-color);
$danger-text: var(--delete-button-text-color);
$danger-hover: var(--delete-button-hover-color);
$danger-active: var(--delete-button-active-color);

$pink: #fc5296;
$teal: #1caf9f;
$purple: #8927ec;
$success: #09ad95;
$warning: #f7b731;
$info: #45aaf2;
$muted: #76839a;
$orange: #fc7303;
$red: #e73827;
$lime: #7bd235;
$dark: #343a40;
$indigo: #6574cd;
$cyan: #007ea7;
$azure: #45aaf2;
$white: #fff;
$black: #000;
$light: #f2f2f9;
$green: #4ecc48;
$blue: #3223f1;
$yellow: #FBB034;


/*Gradient variables*/
$primary-gradient-1: linear-gradient(to bottom right, #9e88f5 0%, #6259ca 100%);
$secondary-gradient: linear-gradient(to bottom right, #9070ff 0%, #ff5d9e 100%);
$warning-gradient: linear-gradient(to bottom right, #f66b4e 0%, #fbc434 100%);
$info-gradient: linear-gradient(to bottom right, #1e63c3 0%, #00f2fe 100%);
$danger-gradient: linear-gradient(to bottom right, #f1bf64 0%, #f71d36 100%);
$success-gradient: linear-gradient(to bottom right, #4be8d4 0%, #129bd2 100%);

/*white variables*/
$white-1: rgba(255, 255, 255, 0.1);
$white-2: rgba(255, 255, 255, 0.2);
$white-3: rgba(255, 255, 255, 0.3);
$white-4: rgba(255, 255, 255, 0.4);
$white-5: rgba(255, 255, 255, 0.5);
$white-6: rgba(255, 255, 255, 0.6);
$white-7: rgba(255, 255, 255, 0.7);
$white-8: rgba(255, 255, 255, 0.8);
$white-9: rgba(255, 255, 255, 0.9);
$white-05: rgba(255, 255, 255, 0.05);
$white-08: rgba(255, 255, 255, 0.08);
$white-75: rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1: rgba(0, 0, 0, 0.1);
$black-2: rgba(0, 0, 0, 0.2);
$black-3: rgba(0, 0, 0, 0.3);
$black-4: rgba(0, 0, 0, 0.4);
$black-5: rgba(0, 0, 0, 0.5);
$black-6: rgba(0, 0, 0, 0.6);
$black-7: rgba(0, 0, 0, 0.7);
$black-8: rgba(0, 0, 0, 0.8);
$black-9: rgba(0, 0, 0, 0.9);
$black-05: rgba(0, 0, 0, 0.05);


